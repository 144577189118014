var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "slider",
    },
    [
      _c("div", { staticClass: "line" }),
      _c("div", { staticClass: "subline inc" }),
      _c("div", { staticClass: "subline dec" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }