<template>
  <div v-show="isLoading" class="slider">
    <div class="line"></div>
    <div class="subline inc"></div>
    <div class="subline dec"></div>
  </div>
</template>
<script>
export default {
  name: 'IndeterminateLoading',
  props: {
    isLoading: {
      type: Boolean
    }
  }
}
</script>
<style scoped>
.slider {
  position: absolute;
  width: 1000px;
  height: 5px;
  overflow-x: hidden;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: #4a8df8;
  width: 150%;
  height: 5px;
}

.subline {
  position: absolute;
  background: #4a8df8;
  height: 5px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}

.blur-background {
  filter: blur(2px);
}
.online-meeting {
  min-height: 300px;
}
.message-data-sidebar {
  position: relative;
  z-index: 99999;
}

.slider {
  position: relative;
  width: 100%;
  height: 5px;
  overflow-x: hidden;
}

.line {
  position: absolute;
  opacity: 0.4;
  background: #275D73;
  width: 150%;
  height: 5px;
}

.subline {
  position: absolute;
  background: #275D73;
  height: 5px;
}
.inc {
  animation: increase 2s infinite;
}
.dec {
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
  from {
    left: -5%;
    width: 5%;
  }
  to {
    left: 130%;
    width: 100%;
  }
}
@keyframes decrease {
  from {
    left: -80%;
    width: 80%;
  }
  to {
    left: 110%;
    width: 10%;
  }
}
</style>
