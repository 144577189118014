<template>
  <div class="h-screen flex w-full items-start justify-center" id="pa-shopify">
    <div class="pa-shopify">
      <a href="https://www.letsconnect.at/" class="register-funnel__main-block__heading__logo-wrapper">
        <img src="@/assets/images/register-funnel/LetsConnect_Logo.svg" alt="Logo" class="register-funnel__main-block__heading__logo-img" />
      </a>
      <div class="pa-shopify--body">
        <div class="pa-shopify--title lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
          {{ $t('shopify.authenticate') }}
          <indeterminate-loading :isLoading="isLoading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { muser } from '@/mixins/muser'
import IndeterminateLoading from '@/components/IndeterminateLoading.vue'

export default {
  mixins: [muser],
  components: {
    IndeterminateLoading
  },
  data: () => ({
    shop: null,
    state: null,
    redirectUri: null,
    scopes: 'read_script_tags', //scopes: 'read_script_tags,write_script_tags',
    isLoading: true
  }),

  computed: {
    apiKey() {
      return btoa(this.$encryptedShopifyApiKey)
    }
  },

  async created() {},

  async beforeMount() {
    await this.LOGOUT_USER()
    this.state = this.nonce(32)
    if (this.$route.query.shop) {
      this.shop = this.$route.query.shop
      this.redirectUri = `${this.$appURL}/shopify-callback`
      const installUrl = `https://${this.shop}/admin/oauth/authorize?client_id=${this.apiKey}&scope=${this.scopes}&state=${this.state}&redirect_uri=${this.redirectUri}`
      localStorage.setItem('pa-shopify-state', atob(this.state))
      window.location = installUrl
    }
  },

  methods: {
    nonce(length) {
      let text = ''
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
      return text
    }
  }
}
</script>

<style scoped lang="scss">
.pa-shopify {
  padding: 20px;
  width: calc(70vw);
  &--body {
    background-color: rgba(225, 225, 225, 0.2);
    border-radius: 6px;
  }
  &--title {
    color: #262629;
    font-family: 'Larsseit-Bold';
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.177778px;
  }
}
@font-face {
  font-family: 'Larsseit-Regular';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}

@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}
</style>
