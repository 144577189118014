var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-screen flex w-full items-start justify-center",
      attrs: { id: "pa-shopify" },
    },
    [
      _c("div", { staticClass: "pa-shopify" }, [
        _vm._m(0),
        _c("div", { staticClass: "pa-shopify--body" }, [
          _c(
            "div",
            {
              staticClass:
                "pa-shopify--title lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base",
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("shopify.authenticate")) +
                  "\n        "
              ),
              _c("indeterminate-loading", {
                attrs: { isLoading: _vm.isLoading },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "register-funnel__main-block__heading__logo-wrapper",
        attrs: { href: "https://www.letsconnect.at/" },
      },
      [
        _c("img", {
          staticClass: "register-funnel__main-block__heading__logo-img",
          attrs: {
            src: require("@/assets/images/register-funnel/LetsConnect_Logo.svg"),
            alt: "Logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }